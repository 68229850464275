/* eslint-disable camelcase */
import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';
import {
  PageLayout,
  PageHeading,
  LearningHub,
  BlogFeed,
  MediaAppearance,
} from 'components';
import { LearningHubTypes } from 'types';
import RightArrowIcon from 'assets/svg/arrow-right.svg';

const LearningHubPage: FC<LearningHubTypes> = ({ data }) => {
  const { title, tagline, description, slug, seo, banner } = data.Learninghub;
  const {
    LearninghubArticles,
    LearninghubRecipes,
    LearninghubMediaAppearances,
  } = data;
  const blogpageslug = data.StrapiBlogPage;
  const receipepageslug = data.StrapiRecipesPage;

  return (
    <PageLayout
      title="LearningHub"
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.localFile?.publicURL}
      hasContainerClass
      delaySec={0.8}
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
    >
      <PageHeading
        title={title}
        subtitle={tagline}
        description={description}
        level={1}
      />
      <section>
        <PageHeading title="Articles" />

        <div className="learninghub_section">
          <div className="blog_feed">
            {LearninghubArticles.edges.map((item: any, index: number) => {
              return index < 3 ? (
                <BlogFeed
                  Blogs={item.node}
                  characterLimit={200}
                  key={item.node.id}
                  blogUrl={`${slug}/${blogpageslug.slug}`}
                  index={index + 1}
                />
              ) : null;
            })}
          </div>

          <Link
            to={`${blogpageslug.slug}`}
            className="learninghub_section_view_more"
          >
            <span className="learninghub_section_view_more_link_text">
              More Articles
            </span>

            <RightArrowIcon />
          </Link>
        </div>
      </section>
      <section>
        <PageHeading title="Recipes" />
        <div className="learninghub_section">
          <div className="learninghub">
            {LearninghubRecipes.edges.map((recipe: any, index: number) => {
              return index < 3 ? (
                <LearningHub
                  Item={recipe.node}
                  Parentslug={`${slug}/${receipepageslug.slug}`}
                  key={recipe.node.id}
                />
              ) : null;
            })}
          </div>

          <Link
            to={`${receipepageslug.slug}`}
            className="learninghub_section_view_more"
          >
            <span className="learninghub_section_view_more_link_text">
              More Recipes
            </span>
            <RightArrowIcon />
          </Link>
        </div>
      </section>

      <section>
        <PageHeading title="Media Hub" />
        <div className="learninghub_section">
          <div className="learninghub">
            {LearninghubMediaAppearances.edges.map(
              (media: any, index: number) => {
                return index < 3 ? (
                  <MediaAppearance Media={media.node} key={media.node.id} />
                ) : null;
              }
            )}
          </div>

          <Link
            to={`${data.StrapiMediaHub.slug}`}
            className="learninghub_section_view_more"
          >
            <span className="learninghub_section_view_more_link_text">
              More Media Appearances
            </span>
            <RightArrowIcon />
          </Link>
        </div>
      </section>
    </PageLayout>
  );
};

export default LearningHubPage;

export const query = graphql`
  query LearningHubPageQuery {
    Learninghub: strapiLearningHubPage {
      slug
      tagline
      title
      description: pageDescription
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
    }
    LearninghubArticles: allStrapiArticle(
      sort: { fields: strapiId, order: DESC }
    ) {
      edges {
        node {
          id
          title
          description
          slug
          content
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          video {
            localFile {
              publicURL
            }
          }
          imageLink
        }
      }
    }
    LearninghubRecipes: allStrapiRecipe(
      sort: { fields: strapiId, order: DESC }
    ) {
      edges {
        node {
          slug
          title
          duration
          id
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 518, height: 518)
              }
            }
          }
        }
      }
    }
    LearninghubMediaAppearances: allStrapiMediaAppearance(
      sort: { fields: strapiId, order: DESC }
    ) {
      edges {
        node {
          id
          content
          mediaLink
          title
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600, height: 400)
              }
            }
          }
        }
      }
    }
    StrapiBlogPage: strapiBlogPage {
      slug
    }
    StrapiRecipesPage: strapiRecipesPage {
      slug
    }
    StrapiMediaHub: strapiMediaHub {
      slug
    }
  }
`;
